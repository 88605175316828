<template>
    <div class="partners-section container-l flex flex-col mx-auto">
        <div class="container flex flex-col mx-auto">
            <div class="partners-head">
                <div class="partners-content w-full sm:w-2/5">
                    <h2>{{ $t("headings.ourPartners") }}</h2>
                    <p>{{ $t('misc.ourPartnersDesc') }}</p>
                </div>
                <div class="partners-img mx-auto sm:mx-0 mt-4 sm:mt-0 w-10/12 sm:w-1/4">
                    <figure>
                        <img
                            src="@/assets/images/partner-grafic.svg"
                            alt=""
                        />
                    </figure>
                </div>
            </div>
            <div class="partners-slider">
                <vue-slick-carousel
                    v-bind="settings"
                    v-if="partners"
                >
                    <!-- <figure v-for="partner in partners" :key="partner.id" class="px-4">
					<img class="" :src="partner.image.path" alt="" />
				</figure> -->
                    <figure class="px-4">
                        <img
                            class=""
                            src="@/assets/images/part1.png"
                            alt=""
                        />
                    </figure>
                    <figure class="px-4">
                        <img
                            class=""
                            src="@/assets/images/part2.png"
                            alt=""
                        />
                    </figure>
                    <figure class="px-4">
                        <img
                            class=""
                            src="@/assets/images/part3.png"
                            alt=""
                        />
                    </figure>
                    <figure class="px-4">
                        <img
                            class=""
                            src="@/assets/images/part4.png"
                            alt=""
                        />
                    </figure>
                    <figure class="px-4">
                        <img
                            class=""
                            src="@/assets/images/part5.jpg"
                            alt=""
                        />
                    </figure>
                    <figure class="px-4">
                        <img
                            class=""
                            src="@/assets/images/part1.png"
                            alt=""
                        />
                    </figure>
                    <figure class="px-4">
                        <img
                            class=""
                            src="@/assets/images/part2.png"
                            alt=""
                        />
                    </figure>
                    <figure class="px-4">
                        <img
                            class=""
                            src="@/assets/images/part3.png"
                            alt=""
                        />
                    </figure>
                    <figure class="px-4">
                        <img
                            class=""
                            src="@/assets/images/part4.png"
                            alt=""
                        />
                    </figure>
                    <figure class="px-4">
                        <img
                            class=""
                            src="@/assets/images/part5.jpg"
                            alt=""
                        />
                    </figure>
                </vue-slick-carousel>
            </div>
        </div>
    </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
    data () {
        return {
            partners: [
                { id: 1, image: { path: "https://via.placeholder.com/300x100" } },
                { id: 2, image: { path: "https://via.placeholder.com/150x100" } },
                { id: 3, image: { path: "https://via.placeholder.com/150x100" } },
                { id: 4, image: { path: "https://via.placeholder.com/300x100" } },
                { id: 5, image: { path: "https://via.placeholder.com/200x100" } },
                { id: 6, image: { path: "https://via.placeholder.com/200x100" } },
                { id: 7, image: { path: "https://via.placeholder.com/300x100" } },
                { id: 8, image: { path: "https://via.placeholder.com/300x100" } },
                { id: 9, image: { path: "https://via.placeholder.com/300x100" } }
            ],
            settings: {
                dots: false,
                arrows: false,
                infinite: true,
                centerMode: true,
                centerPadding: "50px",
                slidesToShow: 5,
                slidesToScroll: 1,
                speed: 500,
                variableWidth: true,
                focusOnSelect: true,
                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: 4
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 550,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            }
        };
    },
    components: {
        VueSlickCarousel
    }
};
</script>
