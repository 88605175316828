<template>
	<div>
		<!-- <div
			class="team-card"
			@mouseover="isHover = true"
			@mouseleave="isHover = false"
		>
			<figure class="member-img">
				<img v-if="card.image" :src="card.image" alt="" />
				<img v-else src="@/assets/images/work-card-1.png" alt="" />
			</figure>
			<div class="flex h-16">
				<figure class="member-before">
					<img
						class="w-full h-full"
						src="@/assets/images/member-before.svg"
						alt=""
					/>
				</figure>
				<div class="member-info">
					<p class="text-2xl">{{ card.name }}</p>
					<p v-if="isHover" class="text-base">{{ card.title }}</p>
				</div>
			</div>
		</div> -->
		<div class="team-card">
			<figure class="member-img">
				<!-- <img v-if="card.image" :src="card.image" alt="" />
				<img v-else src="@/assets/images/work-card-1.png" alt="" /> -->
				<!-- <img src="@/assets/images/work-card-1.png" alt="" /> -->
				<img :src="teamcard.image" alt="" />
			</figure>
			<div class="member-info-container">
				<figure class="member-before">
					<img
						class="w-full h-full"
						src="@/assets/images/member-before.svg"
						alt=""
					/>
				</figure>
				<div class="member-info">
					<p class="text-base p-3 pb-2">{{ teamcard.name }}</p>
					<p class="text-base text-gray-700 pb-3">{{ teamcard.title }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	// name: "HelloWorld",
	props: ["teamCard", "withHover", "teamcard", "cardname", "cardtitle"],
	data() {
		return {
			card: null,
			isHover: false
		};
	},
	mounted() {
		this.card = this.teamCard;
	}
};
</script>
