<template>
  <div class="page--community">
    <section>
      <div class="our-team-section container container-l mx-auto">
        <h2 class="title text-4xl xs:text-5xl sm:text-6xl">
          {{ $t("headings.ourTeam") }}
        </h2>
        <team-section />
        <div class="text-center">
          <router-link to="/job">
            <button class="btn is-main">{{ $t("labels.beWithUs") }}</button>
          </router-link>
        </div>
      </div>
    </section>
    <section>
      <opinion />
    </section>
    <section>
      <partners />
    </section>
  </div>
</template>
<script>
import Opinion from "@/components/Opinion.vue";
import TeamSection from "../components/TeamSection.vue";
import Partners from "../components/Partners.vue";

export default {
  components: {
    Opinion,
    TeamSection,
    Partners
  }
};
</script>
