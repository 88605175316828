<template>
	<section class="opinion-slider container-l">
		<div class="bgopinion bg-no-repeat bg-cover">
			<div class="container h-auto flex flex-col items-center mx-auto py-20">
				<h1 class="flex mx-auto mt-5 block text-2xl">
					{{ $t("headings.ourCustomersOpinion") }}
				</h1>
				<carousel-3d :perspective="0" :space="500" :display="3" :width="500">
					<slide v-for="(slide, i) in slides" :index="i" :key="slide">
						<div class="flex h-full items-center">
							<div class="person">
								<div class="flex items-end">
									<div class="bg-white mx-3 picture w-1/4">
										<!-- <img alt="" src="@/assets/images/oinion.svg" /> -->
									</div>

									<div
										class="bg-white disc raduies disc hidden flex items-center justify-center w-3/4 px-3"
									>
										<p
											class="text-sm font-medium text-center h-full spx-3 border-8d m-3 py-6"
										>
											Lorem Ipsum has been the industry's standard dummy text
											ever since the 1500s,
										</p>
									</div>
								</div>

								<div class="flex">
									<figure class="tree mx-3 w-1/2">
										<img class="" src="@/assets/images/tree.svg" alt="" />
									</figure>

									<div
										class="text-xs flex items-end justify-center text-centerc name h-fulld"
									>
										<span class="h-full p-2"> Ahmed Swerky </span>
									</div>
								</div>
							</div>
						</div>
					</slide>
				</carousel-3d>
			</div>
		</div>
	</section>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
	data() {
		return {
			slides: 7
		};
	},
	components: {
		Carousel3d,
		Slide
	}
};
</script>
