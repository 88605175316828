<template>
  <div class="team-section">
    <div
      v-for="team in teams"
      :key="team.id"
      class="flex flex-wrap items-center mb-16"
    >
      <div class="flex flex-wrap w-full lg:w-3/4 team-members">
        <div
          v-for="member in team.members"
          :key="member.id"
          class="w-full xs:w-1/2 lg:w-1/3 px-4 my-8"
        >
          <team-card :teamcard="member" />
          <!-- :cardname="member.name"
            :cardtitle="member.title" -->
        </div>
      </div>
      <div class="w-1/2 lg:w-1/4 text-center team-detail">
        <figure class="w-full h-full">
          <img class="mx-auto" :src="team.image" alt="" />
        </figure>
        <h2 class="text-3xl mt-4 capitalize">{{ team.title }}</h2>
      </div>
    </div>
  </div>
</template>
<script>
import TeamCard from "./TeamCard.vue";
export default {
  components: { TeamCard },
  data() {
    return {
      teams: [
        {
          id: 1,
          title: "Management",
          image: "images/eng2.png",
          members: [
            {
              name: "Mohamed Nawareg ",
              title: "CEO",
              image: "images/eng.png"
            }
          ]
        },
        {
          id: 1,
          title: "Developers",
          image: "images/developers.png",
          members: [
            {
              name: "Ahmed Swerky ",
              title: "Team Leader",
              image: "images/leader.png"
            },
            {
              name: "Abd Al-Rahman Nashaat",
              title: "Backend Developer",
              image: "images/abdelrahman.png"
            },
            {
              name: "Osama",
              title: "Flutter Developer",
              image: "images/OSAMA.png"
            },
            {
              name: "Ahmed Nawareg",
              title: "Frontend Developer",
              image: "images/ahmed.png"
            },
            {
              name: "Eman Swerky",
              title: "Frontend Developer",
              image: " images/eman.png"
            }
          ]
        },
        {
          id: 1,
          title: "Designers",
          image: "images/designer.png",
          members: [
            {
              name: "Nawal ",
              title: "Designer",
              image: "images/NN.png"
            }
          ]
        }
        // {
        //   id: 4,
        //   title: "developers",
        //   image: "https://via.placeholder.com/300",
        //   members: [
        //     {
        //       name: "card1",
        //       title: "web developer",
        //       image: "https://via.placeholder.com/150"
        //     },
        //     {
        //       name: "card1",
        //       title: "web developer",
        //       image: "https://via.placeholder.com/150"
        //     },
        //     {
        //       name: "card1",
        //       title: "web developer",
        //       image: "https://via.placeholder.com/150"
        //     },
        //     {
        //       name: "card1",
        //       title: "web developer",
        //       image: "https://via.placeholder.com/150"
        //     },
        //     {
        //       name: "card1",
        //       title: "web developer",
        //       image: "https://via.placeholder.com/150"
        //     }
        //   ]
        // },
        // {
        //   id: 2,
        //   title: "developers",
        //   image: "https://via.placeholder.com/300",
        //   members: [
        //     {
        //       name: "card1",
        //       title: "web developer",
        //       image: "https://via.placeholder.com/150"
        //     },
        //     {
        //       name: "card1",
        //       title: "web developer",
        //       image: "https://via.placeholder.com/150"
        //     }
        //   ]
        // },
        // {
        //   id: 3,
        //   title: "developers",
        //   image: "https://via.placeholder.com/300",
        //   members: [
        //     {
        //       name: "card1",
        //       title: "web developer",
        //       image: "https://via.placeholder.com/150"
        //     },
        //     {
        //       name: "card1",
        //       title: "web developer",
        //       image: "https://via.placeholder.com/150"
        //     },
        //     {
        //       name: "card1",
        //       title: "web developer",
        //       image: "https://via.placeholder.com/150"
        //     }
        //   ]
        // }
      ]
    };
  }
};
</script>
